@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts';

* {
  @apply text-black-light font-primary;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#drag-area::-webkit-scrollbar,
#drag-area-mobile::-webkit-scrollbar,
#drag-area-desktop::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#drag-area,
#drag-area-mobile,
#drag-area-desktop {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.radial-blue-gradient {
  background: radial-gradient(128.04% 117.47% at 19.63% -2.73%, #6bd6ff 0%, #0067dc 100%);
}

.blue-to-purple-gradient {
  background: linear-gradient(112.79deg, #52b4f9 0%, #8400f7 100%);
}

.purple-to-blue-gradient {
  background: linear-gradient(112.79deg, #8400f7 0%, #52b4f9 100%);
}

.blue-to-aqua-gradient {
  background: linear-gradient(180deg, #52b4f9 0%, #77fadb 100%);
}

.blue-to-aqua-gradient-up {
  background: linear-gradient(0deg, #52b4f9 0%, #77fadb 100%);
}

.select__control {
  border: 1px solid #f4f4f5 !important;
  border-radius: 4px;
  background: #ffffff;
  padding: 0px 10px !important;
  cursor: pointer !important;
  font-size: 16px;
  box-shadow: none !important;
}

.select__control:focus {
  border: 1px solid #bb80f6 !important;
  box-shadow: 0 0 #0000, 0 0 0 calc(4px + 0px) #f1e6fd, 0 0 #0000 !important;
}

.select__control:active {
  border: 1px solid #bb80f6 !important;
  box-shadow: 0 0 #0000, 0 0 0 calc(4px + 0px) #f1e6fd, 0 0 #0000 !important;
}

.select__control:hover {
  border: 1px solid #bb80f6 !important;
  padding: 0px 10px;
}

.select__value-container {
  padding: 0 !important;
}

.select__single-value {
  font-size: 16px;
  font-weight: 700;
  color: #18181b !important;
}

.rbc-row-content {
  z-index: 0 !important;
}
.rbc-overlay {
  z-index: 10 !important;
}

.select__control .select__clear-indicator {
  display: none;
}

.assessment-modal .close-icon {
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 100;
}

.assessment-modal .modal-container {
  padding: 0;
}

.assessment-modal .modal-container > div {
  padding: 16px;
  height: 100vh;
  margin: 0;
  background: #ffffff;
}

@media only screen and (max-width: 768px) {
  .question-container .close-icon-wrapper {
    right: 1px;
  }
}

#notficationList::-webkit-scrollbar,
#MainContainer::-webkit-scrollbar {
  width: 10px;
}

#notficationList::-webkit-scrollbar-thumb,
#MainContainer::-webkit-scrollbar-thumb {
  background-color: #d4d4d8;
  border-radius: 100px;
  height: 100px;
}
