:root {
  --app-height: 100%;
  --second-font: 'Futura Std';
  --font-weight-bold: 500;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}


.dark.messaging__channel-list {
  background: #212326;
}

.light.messaging__channel-list {
  background: #fafafa;
}

.str-chat.str-chat-channel.messaging {
  background: #fafafa;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06), 0px 2px 30px rgba(0, 0, 0, 0.1);
  height: 700px
}

.dark.str-chat.str-chat-channel.messaging {
  background: #212326;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.str-chat.str-chat-channel-list.messaging {
  background: #fafafa;
}

.dark.str-chat.str-chat-channel-list.messaging {
  background: #212326 !important;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0 !important;
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0;
}

.messaging.str-chat.dark .str-chat__list {
  padding: 0 30px 0;
  background: #282a2d;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 30px 30px 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) {
  padding: 20px 0 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) .messaging__channel-header {
  border-radius: 10px 0px 0px 0px;
}

.str-chat__message-simple-status {
  display: none;
}

.messaging.str-chat.dark .str-chat__message--system__date {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__text p {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__line {
  background: rgba(255, 255, 255, 0.1);
}

/* Mobile View */
@media screen and (max-width: 640px) {
  /*
   * This is to prevent the browser navigation bars from hiding the app
   * on some mobile browsers. The app-height variable is set dynamically
   * using javascript.
   */
  .str-chat-channel {
    height: var(--app-height);
  }

  #mobile-channel-list {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100vw, 0, 0);
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  }

  #mobile-channel-list.show {
    transform: translate3d(0vw, 0, 0);
    z-index: 1000;
  }
}

/* To fix inherited styles (as per Team and Customer Support apps */
@media screen and (max-width: 960px) {
  .str-chat-channel-list.messaging {
    position: unset;
    left: unset;
    top: unset;
    z-index: unset;
    min-height: unset;
    overflow-y: unset;
    box-shadow: unset;
    transition: unset;
  }
}

.str-chat-channel-list{
  float: none !important;
  background: #F4F4F5 !important;
}
